<template>
  <div
    class="cursor-pointer bg-rose-500 hover:bg-rose-600 rounded-md p-1 w-min flex items-center text-white transition-colors"
    @click="abrirMenu"
  >
    <Bars3Icon class="sm:h-8 sm:w-8 w-6 h-6" />
    <p v-if="mostrarTexto" class="ml-2 text-xl leading-7 font-bold pr-1">
      Categorías
    </p>
  </div>
</template>

<script setup lang="ts">
  import { abrirMenu } from "@stores/app";
  import { Bars3Icon } from "@heroicons/vue/24/solid";
  interface Props {
    mostrarTexto?: boolean;
  }
  defineProps<Props>();
</script>
